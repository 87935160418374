import Vue from "vue";

function initialState() {
  return {
    dados : [],
  }
}

var vm_store_EmpreendMapa = new Vue({
  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },
  }
    
})

export default vm_store_EmpreendMapa
