<template>
  <v-container id="ContatoConsultaMobile" class="pa-0" v-bind:style="{ 'background-color':COR_BACKGROUND_TEXT }">
    
    <!-- Botões de ação cabeçalho -->
    <v-toolbar-title
      v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="d-flex align-center"
      tabindex="-1"
      dark
      elevation="0"
    >
      <v-text-field
        id="txtBuscar"
        ref="txtBuscar"
        v-on:keyup="filtro = $event.target.value"
        prepend-inner-icon="mdi-magnify"
        dense
        v-model="search"
        cache-items
        class="ml-3 search-input"
        v-bind:class="{'search-input-longo': search_input_focus}"
        flat
        dark
        label="Buscar..."
        solo-inverted
        clearable
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        tabindex="-1"
      >
      </v-text-field>

      <v-btn 
        @click="store_Contato.contato_edicao={}; store_Contato.dialog_cadastro=true"
        class="mr-3 btn-new"
        v-bind:style="{ 'background-color':COR_PRINCIPAL }"
        elevation="0">
        <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
        <span>Novo</span>
      </v-btn>

    </v-toolbar-title>
    <!-- Fim Botões de ação cabeçalho -->

    <v-expansion-panels
      class="pa-4 pt-0 px-0 expansion-panel d-none"
      accordion>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="panel-header font-weight-bold"
          disable-icon-rotate
        >
          Documentos Básicos
          <template v-slot:actions>
            <v-icon color="" class="icon-help">
              mdi-help-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          Conteúdo teste
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-container class="pa-0 mb-16">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="store_Contato.dados"
        single-select
        show-select
        fixed-header
        top="0px"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado"
        
      >
        <template #item="{ item }">
          <tr 
            :search="search"
          >
            <td >
              <v-checkbox
                v-on:change="seleciona($event, item)"
                :value="item.cod_contato == store_ModalNegociacao.contato_selecionado.cod_contato"
                :key="item.cod_contato"
                hide-details
                class="mr-n6"
                  />
            </td>

            <td class="hide-sm py-2" style="width: 100%">
              <span style="font-size: 15px; display:block; color:#385986 !important; " class="font-weight-bold">{{ item.contato_nome }}</span>
              <span style="font-size: 12px; display:block; color:#808080 !important; " >CPF: {{ item.contato_cpfcnpj }}</span>
              <span style="font-size: 12px; display:block; color:#808080 !important; " >{{ item.contato_celular }}</span>
              <span style="font-size: 12px; display:block; color:#808080 !important; " >{{ item.contato_email }}</span>
            </td>

            <td class="hide-sm mobile">
              <v-btn 
                  v-on:click="Editar(item.cod_contato)"
                  icon
                  dark 
                  class="btn-icon ml-n4" 
                  color="grey">
                <v-icon class="icon-action"> mdi-pencil </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>

    <!-- NOVO MODAL USANDO VUETIFY -->
    <v-container v-if="store_Contato.dialog_cadastro">
      <v-row justify="center">
        <v-dialog v-model="store_Contato.dialog_cadastro"
                  content-class="custom-dialog"
                  max-width="440"
                  :retain-focus="false"
                  persistent>
          <v-card class="">
            <ContatoCadastroMobile ref="ContatoCadastroMobile"/>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <!-- NOVO MODAL USANDO VUETIFY -->

  </v-container>
</template>

<script>
import store_Contato from "./store_Contato";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import store_Login from "../Login/store_Login"
import ContatoCadastroMobile from "./ContatoCadastroMobile.vue"
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_BACKGROUND_TEXT } from "../../services/constantes"

export default {
  name: "ContatoConsultaMobile",

  components: {
    ContatoCadastroMobile
  },

  data() {
    return {
      store_Contato           : store_Contato,
      store_ModalNegociacao   : store_ModalNegociacao,
      store_Login             : store_Login,

      COR_PRINCIPAL           : COR_PRINCIPAL,
      COR_SECUNDARIA          : COR_SECUNDARIA,
      COR_BACKGROUND_TEXT     :COR_BACKGROUND_TEXT,

      loading     : false,
      search      : null,

      selected  : [],
      checked   : false,
      search_input_focus : false,

      /* Título Tabela */
      headers: [
        { text: "", },
        { text: "", },
        { text: "Nome",  value: "nome", },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telefone" },
        { text: "", },
        { text: "", },
      ],
    };
  },

  async mounted() {
    await this.busca();
  },

  watch: {
    search(val) {
      this.busca_timeout(val);
    },
  },

  methods: {
    onBlur() {
        this.search_input_focus = false;
    },

    onFocus() {
        this.search_input_focus = true;
    },

    seleciona(value, item) {
      if (value) {
        //Vue.set(this.store_ModalNegociacao.contato_selecionado, null, item);
        this.store_ModalNegociacao.contato_selecionado = item;
        //console.log("this.store_ModalNegociacao.contato_selecionado", this.store_ModalNegociacao.contato_selecionado)
      }
      else {
        this.store_ModalNegociacao.contato_selecionado = {};
        //console.log("this.store_ModalNegociacao.contato_selecionado", this.store_ModalNegociacao.contato_selecionado)
      }
    },

    async busca(p_params) {
      this.loading = true;
      var lo_params = {};
      if (p_params)
        lo_params = { filtro: p_params };
      this.store_ModalNegociacao.contato_selecionado = [];
      await this.store_Contato.ContatoGet(lo_params);
      this.loading = false;
    },

    async Editar(p_contato) {
      var params = {cod_contato : p_contato}
      this.store_Contato.contato_edicao = {};
      this.store_Contato.contato_edicao = await this.store_Contato.ContatoGet(params);
      //console.log("this.store_Contato.contato_edicao", this.store_Contato.contato_edicao);
      this.store_Contato.dialog_cadastro = true;
    },

    busca_timeout(val) {
      if (val) {
        val = val.trim();
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.busca(val);
      }, 450);
    },
  },
};
</script>

<style scoped>
  /*-----------------------------------*/
  /*------ TABLE ------*/
  /*-----------------------------------*/
  .main-title {
    background: #0548a3;
  }

  @media (min-width: 1024px) {
    /* .container-title {
      max-width: calc(100% - 32px) !important;
    } */
  }

  .text-white {
    color: white;
  }

  .accordion-panel {
    box-shadow: none !important;
  }

  .expansion-panel {
    border-radius: 8px 8px 0px 0px;
  }

  .v-expansion-panel::before {
    box-shadow: none !important;
  }

  .panel-header {
    height: 60px;
    font-family: "Montserrat", sans-serif !important;
    font-size: 17px;
    background: #f6f6f6 !important;
    color: #4476ba;
  }

  .wrapper-title,
  .v-toolbar,
  .v-sheet {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
  }

  /* .v-toolbar__content {
    padding-left: 0px !important;
    margin-top: 10px !important;
  } */

  .v-input__control {
    background: #2a63b1 !important;
  }

  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  @media (max-width: 768px) {
    .search-input {
      margin: 0.8rem 10% 1.2rem 0;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      /* min-width: calc(100% - 15px); */

    }
  }

  .v-input__control,
  .v-input__slot,
  .v-select__slot {
    height: 35px !important;
  }

  .v-label {
    margin-right: 0px !important;
  }

  .icon-action {
    font-size: 1.3rem !important;
  }

  .container-content {
    border-radius: 20px 20px !important;
    background: #fff !important;
  }

  @media (max-width: 768px) {
    .main-title {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }

    .container-content {
      max-width: 740px !important;
    }
  }

  @media (max-width: 599px) {
    .container-content {
      padding: 16px !important;
    }
    
    .search-input {
      margin: 0.9rem 10% 1.2rem 0;
      min-width: auto;
    }
    
    .search-input-longo {
      margin: 0.9rem 10% 1.2rem 0;
      height: 35px !important;
      /* min-width: calc(116%); */
    }

  }

  .container-table {
    max-width: calc(100% - 32px) !important;
    border-radius: 8px !important;
    background: #fff !important;
  }

  .wrapper-title div {
    padding-left: 0px !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
  }

  .v-toolbar__title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .wrapper-title {
    margin-bottom: 12px;
  }

  .row-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    margin: 0px;
  }

  .col-main {
    padding: 0px !important;
    margin: 0px !important;
    width: 100% !important;
  }

  .col-avatar {
    padding-left: 16px !important;
  }

  .nav-link {
    padding: 8px 0px;
  }

  .v-btn {
    font-size: 12px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .btn-new {
    font-size: 12px;
    color: white;
    width: 100px;
  }

  .table-height {
    height: 300px !important;
  }

</style>