<template>
  <v-container class="pa-0" id="CorretorConsultaMobile">

      <v-card elevation="0" class="d-flex flex-column pt-0" style="min-height:420px">
        <v-card-text class="pa-6" style="">
          <v-container class="pa-0">
            <v-form class="mt-0 pt-0" ref="form">

              <div class="pa-0 mt-n2" style="width:100%; text-align:center;">
                <v-avatar color="#909090" size="92">
                  <v-avatar
                    v-if="store_ModalNegociacao.corretor_selecionado.corretor_foto"
                    size="90"
                    rounded="false"
                  >
                    <v-img v-bind:src="store_ModalNegociacao.corretor_selecionado.corretor_foto"></v-img>
                  </v-avatar>
                  <v-avatar
                    v-else
                    color="#D0D0D0"
                    rounded="false"
                    size="90"
                  >
                    <span color="#274ABB" class="headline">
                      {{ nomeIniciais(store_ModalNegociacao.corretor_selecionado.corretor_nome) }}
                    </span>
                  </v-avatar>
                </v-avatar>
              </div>
              <div class="pa-0" style="width:100%; text-align:center;">
                <p class="font-weight-bold body-1 mb-0"
                    style="white-space: pre-line; font-size:16px !important;">
                  {{ store_ModalNegociacao.corretor_selecionado.corretor_nome }}
                </p>
                <p class="body-1 mb-0"
                    style="white-space: pre-line; font-size:13px !important;">
                  {{ store_ModalNegociacao.corretor_selecionado.corretor_email }}
                </p>
                <p class="body-1 mb-5"
                    style="white-space: pre-line; font-size:13px !important;">
                  {{ store_ModalNegociacao.corretor_selecionado.corretor_telefone }}
                </p>
              </div>

              <v-autocomplete
                :items="store_Corretor.dados"
                v-model="store_ModalNegociacao.corretor_selecionado"
                item-text="corretor_nome"
                item-value="cod_pessoa"
                label="Corretor"
                background-color="#F8F8F8"
                filled
                return-object
                required
                dense
                class="mb-n2 mt-n3 p-2"
                :disabled="lb_SomenteCorretor"
              />

              <!-- <v-select
                :items="store_Corretor.dados"
                v-model="store_ModalNegociacao.corretor_selecionado"
                item-text="corretor_nome"
                item-value="cod_pessoa"
                label="Corretor"
                background-color="#F8F8F8"
                filled
                return-object
                required
                dense
                class="mb-n2 mt-n3 p-2"
                :disabled="lb_SomenteCorretor"
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item.corretor_nome"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-select> -->

              <v-autocomplete
                :items="prospeccao"
                v-model="store_ModalNegociacao.prospeccao_selecionada"
                label="Prospecção"
                background-color="#F8F8F8"
                filled
                dense
                item-text="nome"
                item-value="cod_prospeccao"
                return-object
                required
                class="mb-n2 mt-n3"
              />
              <!-- <v-select
                :items="prospeccao"
                v-model="store_ModalNegociacao.prospeccao_selecionada"
                label="Prospecção"
                background-color="#F8F8F8"
                filled
                dense
                item-text="nome"
                item-value="cod_prospeccao"
                return-object
                required
                class="mb-n2 mt-n3"
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item.nome"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-select> -->
            </v-form>
          </v-container>
        </v-card-text>

      </v-card>

  </v-container>
</template>

<script type="text/javascript">

import store_usuario from "../../store/store_usuario"
import store_Corretor from "./store_Corretor"
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao"
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos"
import { nomeIniciais } from "../../services/funcoes"
import { ProspeccaoGet } from "../../services/global"

export default {
  name: "CorretorConsultaMobile",

  props: ['cod_empreendimento'],

  data() {
    return {
      store_usuario         : store_usuario,
      store_Corretor        : store_Corretor,
      store_ModalNegociacao : store_ModalNegociacao,
      store_Empreendimentos : store_Empreendimentos,

      nomeIniciais          : nomeIniciais,
      prospeccao            : [],

      lb_SomenteCorretor    : null,  

    };
  },

  mounted() {
    this.busca_Corretor();
    this.busca_Prospeccao();
  },

  methods: {

    async busca_Corretor() {
      var lo_params = {cod_empreendimento: this.cod_empreendimento};
      this.store_Corretor.dados = await this.store_Corretor.CorretorGet(lo_params);

      //if (this.store_usuario.tipo_cliente == 'USUARIO')
      this.lb_SomenteCorretor    = (this.store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 3)) &&
                                   !this.store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2  || o.cod_funcao == 4 || o.cod_funcao == 5)))
      if (this.lb_SomenteCorretor) {
        this.store_ModalNegociacao.corretor_selecionado = this.store_Corretor.dados.find(o => (o.cod_pessoa == this.store_usuario.user.cod_pessoa));
        //console.log("Corretor Selecionado", this.store_ModalNegociacao.corretor_selecionado);
      }
    },

    async busca_Prospeccao() {
      var ls_Resp = await ProspeccaoGet();
      this.prospeccao = ls_Resp;
    },

  },

};
</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.btn {
  width: 120px;
}
</style>
