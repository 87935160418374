<template>
  <div id="EmpreendimentoFiltroAvancado">
    <v-row class="search-filter-container mt-3 mb-5">
      
      <!-- Col Input Busca Empreendimento -->
      <v-col
        v-if="!store_Empreendimentos.empreend_selecionado.cod_empreendimento"
        cols="12"
        sm="6"
        md="6"
        lg="6"
        class="d-flex input-container pl-2 py-1"
      >
        <input 
          class="sorting dropdown w-100 py-1 pl-2 pr-16 body-2"
          id="busca_empreendimento"
          type="text"
          placeholder="Buscar Empreendimento"
          v-on:keyup="
            store_Empreendimentos.filtra_empreendimento({ params : {filtro: $event.target.value,
                                                                    localizacao: store_Empreendimentos.vm.vm_localizacao_selected,
                                                                    tipo: store_Empreendimentos.vm.vm_tipo_selected,
                                                                    status: store_Empreendimentos.vm.vm_status_selected,
                                                                    quadra: store_Empreendimentos.vm.vm_quadra,
                                                                    lote: store_Empreendimentos.vm.vm_lote,
                                                                    preco_min: store_Empreendimentos.vm.vm_preco_options.preco_min,
                                                                    preco_max: store_Empreendimentos.vm.vm_preco_options.preco_max,
                                                                    area_min: store_Empreendimentos.vm.vm_area_options.area_min,
                                                                    area_max: store_Empreendimentos.vm.vm_area_options.area_max
                                                                    }
                                                         }
            )
          "
        />
      </v-col>

      <!-- Fim Col Input Busca Empreendimento -->
      <!-- <v-col
        v-if="this.$route.path == '/empreendimentos' || this.$route.path == '/'"        
        cols="12"
        sm="6"
        md="6"
        lg="6"
        class="text-none d-flex justify-end align-center pr-1 container-buttons py-1 "
      > -->
        <!-- Botão Empreendimentos -->
        <!-- <template>
          <button
            class="text-none active-view-btn d-flex justify-center align-center px-6 rounded"
            tile
            @click="dialogFiltro = true"
          >
            <span
              class="text-none content-filter text-uppercase"
            >
              Filtro3 Avançado</span
            >
            <v-icon right color="white"> mdi-filter-plus-outline </v-icon>
          </button>
        </template> -->
        <!-- Fim Botão Empreendimentos -->

        <!-- <div class="d-none">
          <a
            onclick="toggleThemes()"
            id="b1"
            class="toggle-btn enabled mr-1 d-flex justify-center align-center"
            title="Lista"
            href="properties-half-map-2.html"
            ><v-icon class="list">mdi-view-list</v-icon></a
          > -->

          <!-- Thema Paineis  ----------------------------------------------------------------------->
          <!-- <a
            onclick="toggleThemes()"
            id="b2"
            class="toggle-btn d-flex justify-center align-center"
            title="Grade"
            href="properties-half-map-3.html"
            ><v-icon class="grid">mdi-view-grid</v-icon></a
          >
        </div>
      </v-col> -->

      <!-- Col Botão EmpreendimentoDetalhe -->
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        class="text-none d-flex align-center container-buttons-detalhes py-1"
      >
        <template>
          <button
            class="text-none active-view-btn d-flex justify-center align-center ml-0 rounded"
            tile
            @click="dialogFiltro = true"
          >
            <span
              class="text-none content-filter-pag-map d-flex"
            >
              Filtro Avançado</span
            >
            <v-icon right color="white"> mdi-filter-plus-outline </v-icon>
          </button>
        </template>
      </v-col>
      <!-- Fim Col Botão EmpreendimentoDetalhe -->

      <!-- Dialog Filtro Avançado -->
      <v-dialog v-model="dialogFiltro" max-width="400px" class="d-none" :retain-focus="false">
        <v-card class="pa-4 card-filter" min-height="600">
          <v-card-title class="d-flex justify-end align-center pa-0 pt-2 pb-2">
            <v-btn
              icon
              dark
              color="primary"
              @click="dialogFiltro = false"
              class="mt-n4"
            >
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <!-- Modal Content Filtro Avançado-- -------------------------------------- -->
          <v-container class="modal-wrapper w-100 pa-0 pt-4">
            <v-form class="pa-4 pt-0 h-100">
              <!-- Quadra (para quando estiver no EmpreendimentoDetalhe) -->
              <div
                v-if="store_Empreendimentos.empreend_selecionado.cod_empreendimento"
                class="filter-item"
              >
                <label>Quadra</label>
                <select
                  v-model="store_Empreendimentos.vm.vm_quadra"
                  class="w-100 mb-4"
                >
                  <option
                    ref="select_quadra"
                    v-for="(option, index) in store_Empreendimentos.empreend_selecionado.quadras"
                    v-bind:key="index"
                    v-bind:value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>

              <!-- Lote (para quando estiver no EmpreendimentoDetalhe) -->
              <div
                v-if="store_Empreendimentos.empreend_selecionado.cod_empreendimento"
                class="filter-item"
              >
                <label>Lote</label>
                <select
                  v-model="store_Empreendimentos.vm.vm_lote"
                  class="w-100 mb-4"
                >
                  <option
                    ref="select_lote"
                    v-for="(option, index) in store_Empreendimentos.empreend_selecionado.lotes"
                    v-bind:key="index"
                    v-bind:value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>

              <!-- Status do Empreendimento -->
              <div
                v-if="!store_Empreendimentos.empreend_selecionado.cod_empreendimento"
                class="filter-item"
              >
                <label>Status do Empreendimento</label>
                <select
                  v-model="store_Empreendimentos.vm.vm_status_selected"
                  class="w-100 mb-4"
                >
                  <option
                    v-for="option in store_Empreendimentos.vm.vm_status_options"
                    v-bind:key="option.value"
                    v-bind:value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>

              <!-- Localização -->
              <div
                v-if="
                  !store_Empreendimentos.empreend_selecionado.cod_empreendimento
                "
                class="filter-item"
              >
                <label>Localização</label>
                <select
                  v-model="store_Empreendimentos.vm.vm_localizacao_selected"
                  class="w-100 mb-4"
                >
                  <option
                    v-for="option_localizacao in store_Empreendimentos.vm.vm_localizacao_options"
                    v-bind:key="option_localizacao.value"
                    v-bind:value="option_localizacao.value"
                  >
                    {{ option_localizacao.text }}
                  </option>
                </select>
              </div>

              <!-- Tipo -->
              <div
                v-if="
                  !store_Empreendimentos.empreend_selecionado.cod_empreendimento
                "
                class="filter-item"
              >
                <label>Tipo</label>
                <select
                  v-model="store_Empreendimentos.vm.vm_tipo_selected"
                  class="w-100 mb-6"
                >
                  <option
                    v-for="option_tipo in store_Empreendimentos.vm.vm_tipo_options"
                    v-bind:key="option_tipo.value"
                    v-bind:value="option_tipo.value"
                  >
                    {{ option_tipo.text }}
                  </option>
                </select>
              </div>

              <!-- Área -->
              <label for="preco1" class="mb-0">Área do Imóvel:</label>
              <div class="range-slider mb-5">
                <div
                  class="w-100 d-flex justify-between pb-4"
                  v-on:change="slider_area"
                >
                  <v-col xs="6" class="pr-0 col-range-1">
                    <v-text-field
                      v-model.number="
                        store_Empreendimentos.vm.vm_area_options.area_min
                      "
                      type="number"
                      :min="0"
                      :max="store_Empreendimentos.vm.vm_area.area_max"
                      filled
                      dense
                      background-color="map-deep-merge white"
                    >
                    </v-text-field>
                  </v-col>
                  <div
                    style="width: 50px"
                    class="text-center ma-auto pb-6 label-range"
                  >
                    até
                  </div>
                  <v-col class="pl-0 col-range-2" xs="6">
                    <v-text-field
                      v-model.number="
                        store_Empreendimentos.vm.vm_area_options.area_max
                      "
                      type="number"
                      :min="0"
                      :max="store_Empreendimentos.vm.vm_area.area_max"
                      filled
                      dense
                      background-color="map-deep-merge white"
                    >
                    </v-text-field>
                  </v-col>
                </div>
                <input
                  v-on:change="slider_area"
                  v-model.number="
                    store_Empreendimentos.vm.vm_area_options.area_min
                  "
                  :min="0"
                  :max="store_Empreendimentos.vm.vm_area.area_max"
                  step="1"
                  type="range"
                />
                <input
                  v-on:change="slider_area"
                  v-model.number="
                    store_Empreendimentos.vm.vm_area_options.area_max
                  "
                  :min="0"
                  :max="store_Empreendimentos.vm.vm_area.area_max"
                  step="1"
                  type="range"
                />
              </div>
              <!-- Fim Área -->

              <!-- Preço -->
              <label for="preco1" class="mb-0">Faixa de Preço:</label>
              <div class="range-slider">
                <div class="w-100 d-flex" v-on:change="slider_preco">
                  <v-col class="pr-0 col-range-3">
                    <v-text-field
                      v-model.number="
                        store_Empreendimentos.vm.vm_preco_options.preco_min
                      "
                      type="number"
                      :min="0"
                      :max="store_Empreendimentos.vm.vm_preco.preco_max"
                      filled
                      dense
                      background-color="map-deep-merge white"
                    ></v-text-field>
                  </v-col>
                  <div
                    style="width: 50px"
                    class="text-center ma-auto pb-6 label-range"
                  >
                    até
                  </div>
                  <v-col class="pl-0 col-range-4">
                    <v-text-field
                      v-model.number="
                        store_Empreendimentos.vm.vm_preco_options.preco_max
                      "
                      type="number"
                      :min="0"
                      :max="store_Empreendimentos.vm.vm_preco.preco_max"
                      filled
                      dense
                      background-color="map-deep-merge white"
                    ></v-text-field>
                  </v-col>
                </div>
                <input
                  v-on:change="slider_preco"
                  v-model.number="
                    store_Empreendimentos.vm.vm_preco_options.preco_min
                  "
                  :min="0"
                  :max="store_Empreendimentos.vm.vm_preco.preco_max"
                  step="1"
                  type="range"
                />
                <input
                  v-on:change="slider_preco"
                  v-model.number="
                    store_Empreendimentos.vm.vm_preco_options.preco_max
                  "
                  :min="0"
                  :max="store_Empreendimentos.vm.vm_preco.preco_max"
                  step="1"
                  type="range"
                />
              </div>
              <!-- Fim Preço -->

              <!-- Buscar -->
              <div class="filter-item">
                <v-btn
                  block
                  color="primary"
                  class="button alt mt-8 body-1"
                  v-on:click="filtra_empreendimento()"
                  >Buscar Unidades</v-btn
                >
              </div>
              <!-- Fim Buscar -->
            </v-form>
            <!-- Fim Form Contact -->
          </v-container>
          <!-- Fim Container Form Contato -->
          <!-- Fim Modal Content -->
        </v-card>
      </v-dialog>
      <!-- Fim Dialog Filtro Avançado -->

      <!-- -- CHIPS das consultas ------------------------------------- -->
      <v-col class="px-2 py-1">
        <div style="">
          <!-- Quadra (para quando estiver no EmpreendimentoDetalhe) -->
          <v-chip
            v-if="
              store_Empreendimentos.empreend_selecionado.cod_empreendimento >
                0 && chipsQuadra != 'Indiferente'
            "
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_quadra = 'Indiferente'),
                (chipsQuadra = 'Indiferente'),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-home-group</v-icon>{{ chipsQuadra }}</v-chip
          >
          <!-- Lote (para quando estiver no EmpreendimentoDetalhe) -->
          <v-chip
            v-if="
              store_Empreendimentos.empreend_selecionado.cod_empreendimento >
                0 && chipsLote != 'Indiferente'
            "
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_lote = 'Indiferente'),
                (chipsLote = 'Indiferente'),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-home-map-marker</v-icon>{{ chipsLote }}</v-chip
          >

          <!-- Status do Empreendimento -->
          <v-chip
            v-if="
              !store_Empreendimentos.empreend_selecionado.cod_empreendimento &&
              chipsStatus != ''
            "
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_status_selected = ''),
                (chipsStatus = ''),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-help </v-icon>{{ chipsStatus }}</v-chip
          >
          <!-- Localização -->
          <v-chip
            v-if="
              !store_Empreendimentos.empreend_selecionado.cod_empreendimento &&
              chipsLocalizacao != ''
            "
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_localizacao_selected = ''),
                (chipsLocalizacao = ''),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-map-marker</v-icon>{{ chipsLocalizacao }}</v-chip
          >
          <!-- Tipo -->
          <v-chip
            v-if="
              !store_Empreendimentos.empreend_selecionado.cod_empreendimento &&
              chipsTipo != ''
            "
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_tipo_selected = ''),
                (chipsTipo = ''),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-home-city-outline </v-icon>{{ chipsTipo }}</v-chip
          >
          <!-- Área Minima -->
          <v-chip
            v-if="chipsAreaMin > 0"
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_area_options.area_min = 0),
                (chipsAreaMin = 0),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-ruler-square</v-icon>>=
            {{ formatNumber(chipsAreaMin) }}
          </v-chip>
          <!-- Área Máxima -->
          <v-chip
            v-if="
              chipsAreaMax > 0 &&
              chipsAreaMax < store_Empreendimentos.vm.vm_area.area_max
            "
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_area_options.area_max =
                store_Empreendimentos.vm.vm_area.area_max),
                (chipsAreaMax = store_Empreendimentos.vm.vm_area.area_max),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-ruler-square</v-icon>&#60;=
            {{ formatNumber(chipsAreaMax) }}
          </v-chip>
          <!-- Preço Minimo -->
          <v-chip
            v-if="chipsPrecoMin > 0"
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_preco_options.preco_min = 0),
                (chipsPrecoMin = 0),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-currency-usd </v-icon>>=
            {{ formatNumber(chipsPrecoMin) }}
          </v-chip>
          <!-- Preço Máximo -->
          <v-chip
            v-if="
              chipsPrecoMax > 0 &&
              chipsPrecoMax < store_Empreendimentos.vm.vm_preco.preco_max
            "
            class="ma-2 chips_text"
            close
            small
            color="silver"
            outlined
            @click:close="
              (store_Empreendimentos.vm.vm_preco_options.preco_max =
                store_Empreendimentos.vm.vm_preco.preco_max),
                (chipsPrecoMax = store_Empreendimentos.vm.vm_preco.preco_max),
                filtra_empreendimento()
            "
          >
            <v-icon left> mdi-currency-usd </v-icon>&#60;=
            {{ formatNumber(chipsPrecoMax) }}
          </v-chip>
        </div>
      </v-col>
      <!---- Fim CHIPS das consultas ------------------------------------- -->

      <!-- Col Quantidade Lotes Encontrados -->
      <v-col cols="12" class="pb-0">
        <p
          v-if="this.$route.path == '/'"
          class="unidades-encontradas mb-0 font-weight-bold"
        >
          Unidades Encontradas:
          {{ store_Empreendimentos.filtra_empreendimento_total_encontrado }}
        </p>

        <p
          v-else
          class="unidades-encontradas unidades-encontradas-breakpoints mb-0 font-weight-bold pl-5"
        >
          Unidades Encontradas:
          {{ store_Empreendimentos.filtra_empreendimento_total_encontrado }}
        </p>
      </v-col>
      <!-- Fim Col Quantidade Lotes Encontrados -->
    </v-row>
  </div>
</template>

<script type="text/javascript">
// @ is an alias to /src
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO, COR_BACKGROUND } from "../../services/constantes";
import { API, API_BLOB, baseURL } from "../../services/API";
// import "jquery-ui";
// import "jquery-ui-bundle";
// import "range-slider";
// import "bootstrap";
import { EventBus } from "../../services/eventbus";
import { fromLonLat, transformExtent } from "ol/proj.js";
import { formatNumber, getRadDeg } from "../../services/funcoes";

import store_Empreendimentos from "./store_Empreendimentos";

export default {
  components: {},
  data() {
    return {
      store_Empreendimentos : store_Empreendimentos,
      vm_aux                : null,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SECUNDARIA_2  : COR_SECUNDARIA_2,
      COR_BACKGROUND    : COR_BACKGROUND,

      formatNumber: formatNumber,
      getRadDeg: getRadDeg,

      dialogFiltro: false,
      hover: false,
      styleNormal: { background: "white", border: "3px solid white" },
      styleSelecionado: { background: "white", border: "3px solid #2497e3" },

      objectUrl: "",
      baseURL: baseURL,
      rotation: 0,

      //Tag (chip) do filtro avançado
      chipsStatus: store_Empreendimentos.vm.vm_status_selected,
      chipsLocalizacao: store_Empreendimentos.vm.vm_localizacao_selected,
      chipsTipo: store_Empreendimentos.vm.vm_tipo_selected,
      chipsQuadra: store_Empreendimentos.vm.vm_quadra,
      chipsLote: store_Empreendimentos.vm.vm_lote,
      chipsAreaMin: store_Empreendimentos.vm.vm_area_options.area_min,
      chipsAreaMax: store_Empreendimentos.vm.vm_area_options.area_max,
      chipsPrecoMin: store_Empreendimentos.vm.vm_preco_options.preco_min,
      chipsPrecoMax: store_Empreendimentos.vm.vm_preco_options.preco_max,
    };
  },

  mounted() {
    let busca_empreendimento = document.querySelector('#busca_empreendimento')
    busca_empreendimento?.focus()
  },
  watch: {
    // Atrualiza os Vms de acordo com a tela dialogo, se foi alterada
    dialog() {
      if (!this.vm_aux)
        this.vm_aux = JSON.parse(JSON.stringify(this.store_Empreendimentos.vm));

      this.store_Empreendimentos.vm = JSON.parse(JSON.stringify(this.vm_aux));
    },
  },

  methods: {
    slider_area: function () {
      if (
        this.store_Empreendimentos.vm.vm_area_options.area_min >
        this.store_Empreendimentos.vm.vm_area_options.area_max
      ) {
        var tmp = this.store_Empreendimentos.vm.vm_area_options.area_max;
        this.store_Empreendimentos.vm.vm_area_options.area_max = this.store_Empreendimentos.vm.vm_area_options.area_min;
        this.store_Empreendimentos.vm.vm_area_options.area_min = tmp;
      }
    },
    slider_preco: function () {
      if (
        this.store_Empreendimentos.vm.vm_preco_options.preco_min >
        this.store_Empreendimentos.vm.vm_preco_options.preco_max
      ) {
        var tmp = this.store_Empreendimentos.vm.vm_preco_options.preco_max;
        this.store_Empreendimentos.vm.vm_preco_options.preco_max = this.store_Empreendimentos.vm.vm_preco_options.preco_min;
        this.store_Empreendimentos.vm.vm_preco_options.preco_min = tmp;
      }
    },

    // MapaEmpreendimentos_AtualizaPoligonos(array_cod_empreendimentos) {
    //   EventBus.$emit("MapaEmpreendimentos_AtualizaPoligonos", array_cod_empreendimentos);
    // },

    MapaLotes_atualizaPoligonos(p_CodEmp) {
      EventBus.$emit("MapaLotes_atualizaPoligonos", p_CodEmp);
    },

    async filtra_empreendimento() {
      var ls_CodEmp = this.store_Empreendimentos.empreend_selecionado.cod_empreendimento;
      await store_Empreendimentos.filtra_empreendimento({ params : {filtro: this.store_Empreendimentos.vm.vm_filtro,
                                                                    localizacao: this.store_Empreendimentos.vm.vm_localizacao_selected,
                                                                    tipo: this.store_Empreendimentos.vm.vm_tipo_selected,
                                                                    status: this.store_Empreendimentos.vm.vm_status_selected,
                                                                    quadra: this.store_Empreendimentos.vm.vm_quadra,
                                                                    lote: this.store_Empreendimentos.vm.vm_lote,
                                                                    preco_min: this.store_Empreendimentos.vm.vm_preco_options.preco_min,
                                                                    preco_max: this.store_Empreendimentos.vm.vm_preco_options.preco_max,
                                                                    area_min: this.store_Empreendimentos.vm.vm_area_options.area_min,
                                                                    area_max: this.store_Empreendimentos.vm.vm_area_options.area_max,
                                                                    cod_empreendimento: ls_CodEmp
                                                                    }
                                                         }
      );
      this.vm_aux = JSON.parse(JSON.stringify(this.store_Empreendimentos.vm));
      this.chipsStatus = this.store_Empreendimentos.vm.vm_status_selected;
      this.chipsLocalizacao = this.store_Empreendimentos.vm.vm_localizacao_selected;
      this.chipsTipo = this.store_Empreendimentos.vm.vm_tipo_selected;
      this.chipsQuadra = this.store_Empreendimentos.vm.vm_quadra;
      this.chipsLote = this.store_Empreendimentos.vm.vm_lote;
      this.chipsAreaMin = this.store_Empreendimentos.vm.vm_area_options.area_min;
      this.chipsAreaMax = this.store_Empreendimentos.vm.vm_area_options.area_max;
      this.chipsPrecoMin = this.store_Empreendimentos.vm.vm_preco_options.preco_min;
      this.chipsPrecoMax = this.store_Empreendimentos.vm.vm_preco_options.preco_max;
      this.dialogFiltro = false;

    },
  },
};

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};
</script>

<style scoped>
*,
html {
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.page {
  padding: 0 20px;
}
/*-----------------------------------*/
/*------ SEARCH AND FILTER ------*/
/*-----------------------------------*/
/* @media (max-width: 1024px) {
  .search-filter-container {
    padding: 0px 8px !important;
  }

  .search-filter-container .container-buttons {
    padding-left: 0px;
    padding-right: 10px!important;
  }
} */

/* @media (max-width: 1024px) {
  .search-filter-container .container-buttons-detalhes {
    padding-left: 12px!important;
  }
} */

/* @media (max-width: 768px) {
  .search-filter-container .container-buttons-detalhes {
    justify-content: flex-start !important;
    padding-left: 12px!important;
  }
  .search-filter-container .container-buttons-detalhes .active-view-btn {
    width: 100%!important;
  }
} */

/* @media (max-width: 599px) {
  .search-filter-container .input-container {
    width: calc(100% - 10%);
    padding-right: 10px;
  }

  .search-filter-container .container-buttons {
    width: calc(100% - 90%);
    padding-left: 0px !important;
  }

  .search-filter-container .container-buttons-detalhes {
    width: calc(100% - 10%);
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
} */

.dropdown {
  height: 32px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  outline: none;
}

@media(max-width: 1024px) {
 .dropdown {
    padding-right: 10px!important;
  } 
}

.sorting::placeholder {
  color: #000;
  opacity: 0.5;
}

.content-filter,
.content-filter-pag-map {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem !important;
}

@media (max-width: 1024px) {
  .content-filter,
  .content-filter-pag-map {
    margin-right: 8px;
  }
}

@media (max-width: 599px) {
  .content-filter-pag-map {
    display: flex !important;
  }
}

.active-view-btn {
  min-width: 180px!important;
  height: 30px !important;
  margin-left: 5px;
  background: #274abb !important;
  color: #fff !important;
}

@media (max-width: 1024px) {
  .active-view-btn .v-icon {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .active-view-btn {
    width: 70% !important;
  }
  .active-view-btn .v-icon {
    margin-left: 10px;
  }
}

@media (max-width: 599px) {
  .active-view-btn {
    width: 100% !important;
  }

  .active-view-btn .v-icon {
    width: 35px !important;
    margin-left: 0px;
  }
}

.active-view-btn:hover {
  background: #274abb !important;
  color: #fff;
  opacity: 0.9;
}

.list {
  color: #fff;
}

.grid {
  color: #000;
}

.unidades-encontradas {
  font-family: "Montserrat", sans-serif;
  color: #262b30de;
}

@media (max-width: 1024px) {
  .unidades-encontradas-breakpoints {
    padding-left: 0px !important;
  }
}

@media (max-width: 599px) {
  .unidades-encontradas-breakpoints {
    text-align: center;
  }
}

/*-----------------------------------*/
/*------ MODAL FILTER ------*/
/*-----------------------------------*/
.v-dialog {
  height: 680px !important;
  overflow-y: hidden !important;
}

.card-filter {
  overflow-y: hidden;
}

.modal-wrapper {
  height: 100%;
  background: #e8e8e8 !important;
}

.range-slider {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  height: 6em;
}

.range-slider svg,
.range-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #e8e8e8 !important;
}

input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.6em;
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"]:invalid,
input[type="number"]:out-of-range {
  border: 2px solid #ff6347;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #2497e3;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #2497e3;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #2497e3;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animation: 0.2s;
  background: #2497e3;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type="range"] {
  background: #fff;
  padding: 10px;
}

select {
  padding: 9px;
  background: #fff;
}

@media (max-width: 375px) {
  .label-range {
    display: none;
  }

  .col-range-1,
  .col-range-3 {
    padding-right: 15px !important;
    padding-left: 0px !important;
  }

  .col-range-2,
  .col-range-4 {
    padding-right: 0px !important;
    padding-left: 15px !important;
  }
}

/*-----------------------------------*/
/*------ CARD ------*/
/*-----------------------------------*/
@media (max-width: 1024px) {
  .card-column {
    width: 500px !important;
  }
}

@media (max-width: 599px) {
  .card-row {
    display: flex;
    flex-direction: column;
  }

  .col-avatar {
    padding-right: 16px !important;
  }
}

.card-title {
  font-size: 17px !important;
  font-weight: 600;
  text-transform: capitalize !important;
  color: #444;
}

.homes-tag.featured {
  height: 30px;
  font-weight: 600;
  background: #18ba60;
  color: #fff;
}

@media (max-width: 1024px) {
  .homes-tag span {
    display: none;
  }
}

@media (max-width: 768px) {
  .homes-tag span {
    display: inline-block;
    background: #18ba60;
  }
}

@media (max-width: 599px) {
  .homes-tag.featured {
    margin-bottom: 15px;
  }
}

.homes-tag.sale {
  width: 85px;
  height: 30px;
  font-weight: 600;
  background: #e54242;
  color: #fff;
}

.homes-img {
  height: 100%;
}

@media (max-width: 599px) {
  .homes-img {
    margin-top: 30px !important;
  }
}

.footer {
  background: none;
  border-top: 1px solid #ebebeb;
  padding-top: 0.2rem;
  font-size: 13px;
  color: #666;
}

@media (max-width: 1024px) {
  .footer {
    flex-direction: column;
  }

  .footer-value {
    flex-direction: row !important;
  }

  .font-small {
    margin-right: 5px;
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: row;
  }

  .footer-value {
    flex-direction: column !important;
  }
}

@media (max-width: 599px) {
  .footer {
    flex-direction: column;
  }

  .footer-value {
    flex-direction: row !important;
  }
}

.footer-value {
  font-family: "Lato", sans-serif;
  font-size: 17px;
}

.font-small {
  font-size: 15px !important;
}

/*-----------------------------------*/
/*------ MAP ------*/
/*-----------------------------------*/
.map-fixed {
  position: fixed;
  top: 76px !important;
  right: 10px !important;
}

@media (max-width: 1024px) {
  .map-fixed {
    position: fixed;
    top: 76px !important;
    right: 10px !important;
  }
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.4;
  position: relative;
  width: 100%;
  height: 100%;
}

#gallery {
  height: 165px;
  width: 165px;
  max-width: 165px;
  max-height: 165px;
  position: relative;
  background-color: #00ffff;
  align-self: center;
}

#gallery img {
  /* CSS Hack will make it width 100% and height 100% */
  position: absolute;
  opacity: 0.5;
  background-color: #00ff00;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* Maintain aspect ratio */
  max-height: 165px;
  max-width: 165px;
}

.efeito::before,
.efeito::after {
  content: "";
  position: absolute;
  background-color: #fff;
  left: 165px;
  height: 5vw;
  width: 50%;
}
.efeito::before {
  left: 0;
  transform: skewY(5deg);
  transform-origin: bottom left;
}
.efeito::after {
  right: 0;
  transform: skewY(-5deg);
  transform-origin: bottom right;
}

.seta {
  content: "";
  position: relative;
  left: 30px;
  top: 70px;
  height: 35px;
  width: 18px;
}

.botoes_seta {
  background-color: #ff0000;
}

.w-100 {
  width: 100%;
}
</style>
