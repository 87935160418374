import Vue from "vue";
import { API } from "../../services/API"

function initialState() {
  return  {
    dados               : [], 
    dialog_cadastro     : false,
    contato_selecionado : {},
    contato_edicao      : { cod_contato: null,
                            cod_usuario: null,
                            contato_nome: "",
                            contato_cpfcnpj: "",
                            contato_celular: "",
                            contato_rg: "",
                            contato_rg_orgao: "",
                            contato_status: null,
                            contato_nasc_dt: null,
                            contato_cadastro_dt: null,
                            contato_email: "",
                            contato_favorito: "N",
                            contato_profissao: "",
                            contato_estado_civil: "",
                            contato_telefone: "",
                            contato_foto: null,
                          },
  }
}

var vm_store_Contato = new Vue({
  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    async ContatoGet(p_params) {
      const resposta = await API.get("contato", {
                                                  params: {
                                                      ...p_params
                                                  },
                                                  }
                                      );
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          if (p_params && p_params.cod_contato) {
            this.contato_selecionado = resposta.data.result;
            return resposta.data.result
          }
          else {
            //this.contato_selecionado = {}
            this.dados = resposta.data.result;
            return resposta.data.result
          }
        }        
        else {
          this.dados = [];
          return resposta.data.result
        }
      }
    },

    // Importa os contato do aparelho celular para o banco de dados de contatos deste usuário
    async importaContatos(body) {
      const resp = await API.post(`/importacontatos`, JSON.stringify(body));
      const { data } = resp;
      return data;
    },

    async ContatoPost(body) {
      const resp = await API.post(`/contato`, JSON.stringify(body));
      const { data } = resp;
      return data;
    },
      
    async ContatoPut(body) {
      const resp = await API.put(`/contato/${body.cod_contato}`, JSON.stringify(body))
      const { data } = resp;
      return data;
    }

  }
    
})

export default vm_store_Contato
