import Vue from "vue";
import { API } from "../../services/API";
import {
  maskCPFCNPJ,
  maskTELEFONE,
  CpfCnpjIsValid,
  emailIsValid,
  telefoneIsValid,
} from "../../services/funcoes";

var vm_store_Empreendimento = new Vue({
  data() {
    return {
      dados: [],
      itemSelecionado  : 0,
      importarPlanilha : false,
      paginas          : 0,
      currentPage      : 1,

      array_contabil_detalhes: null,
      array_unidades_detalhes: null,
      array_unidades         : [],

      acao: "C", // E=Edicao, I=Inclusão, C=Consulta
      empreendimento_edicao: {}, // Empreendimento Selecionada para Edição

      dialogDadosBasicos: false,
      dialogProprietarios: false,
      dialogQuadras: false,
      dialogRateios: false,

      dialogFinanceiro: false,
      dialogPlanosPagamento: false,

      dialogContabil: false,
      dialogContabilEdicao: false,

      dialogUnidades: false,

      docDadosBasicos: 0,
      docFinanceiro: 0,
      docPrivilegios: 0,
      docContabil: 0,
      docPlanosPagamento: 0,
      docModelosImpressos: 0,
      docDocumentos: 0,
      docUnidades: 0,

      filtro_unidades: {
        // Filtro na Tela de Consulta --------
        quadra                 : null,
        lote                   : null,
        cod_situacao               : null,
        area_metro             : null,
        preco_metro            : null,
        preco_total            : null,
        fase                   : null,
        cadastro_prefeitura    : null,
        permite_desmembramento : null,
        caucionado             : null,
      },

      abas: [
        ["mdi-view-dashboard-outline", "Dados Básicos", 5],
        ["mdi-cog", "Configurações", 0],
        ["mdi-account-lock-open-outline", "Privilégios", 0],
        ["mdi-calculator", "Contábil", 0],
        ["mdi-credit-card-outline", "Planos de Pagamento", 0],
        ["mdi-file-find", "Modelos de Impressos", 0],
        ["mdi-file-document-outline", "Documentos", 0],
        ["1", "separador", 0],
        ["mdi-home-group", "Unidades", 0],
        ["mdi-history", "Histórico de Atividades", 0],
      ],
    };
  },

  methods: {
    atualiza_abas() {
      this.abas = [
        ["mdi-view-dashboard-outline", "Dados Básicos", 0],
        ["mdi-cash-multiple", "Financeiro", this.docFinanceiro],
        ["mdi-account-lock-open-outline", "Privilégios", this.docPrivilegios],
        ["mdi-calculator", "Contábil", this.docContabil],
        ["mdi-credit-card-outline",  "Planos de Pagamento", this.docPlanosPagamento],
        ["mdi-file-find", "Modelos de Impressos", this.docModelosImpressos],
        ["mdi-file-document-outline", "Documentos", this.docDocumentos],
        ["1", "separador", 0],
        ["mdi-home-group", "Unidades", 0],
      ];
    },

    // async EmpreendimentoGet(p_params) {
    //   const resposta = await API.get("empreendimento", {
    //                                               params: {
    //                                                 ...p_params
    //                                               },
    //                                             }
    //                                   );

    //   if (resposta) {
    //     if (resposta.data.result != 'Não possui dados') {
    //       return resposta.data.result
    //     }
    //   }
    // },
    async EmpreendimentoGet(p_params) {
      const resposta = await API.get("empreendimento", {
        params: {
          ...p_params,
        },
      });
      // console.log("resposta::::", p_params ,resposta)

      if (resposta) {
        this.dados = [];
        if (resposta.data.result != "Não possui dados") {
          if (p_params && p_params.cod_empreendimento) {
            // console.log("this.usuario_selecionado--", resposta);
            //this.usuario_selecionado = resposta.data.result;
            return resposta.data.result;
          } else {
            this.dados = resposta.data.result;
            this.paginas = resposta.data.result;
          }
        }
      }
    },

    async EmpreendimentoPost(body) {
      const resp = await API.post(`/empreendimento`, JSON.stringify(body));
      const { data } = resp;
      // console.log(body,data)
      return data;
    },

    async EmpreendimentoPut(body) {
      const resp = await API.put(
        `/empreendimento/${body.cod_empreendimento}`,
        JSON.stringify(body)
      );
      const { data } = resp;
      // console.log('EmpreendimentoPut33333:::', data)

      return data;
    },

    async EmpreendimentoDelete(body) {
      // console.log(body)
      const resp = await API.delete(
        `/empreendimento/${body.cod_empreendimento}`
      );
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },

    // ======================================================================================
    // ====== CONTABIL ======================================================================
    // ======================================================================================
    async getContabil(p_params) {
      const resp = await API.get(`contabil`, { params: { ...p_params } });
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },

    async ContabilPut(body) {
      const resp = await API.put(
        `/contabil/${body.cod_empreendimento}/${body.cod_cont_planoconta_exp}`,
        JSON.stringify(body)
      );
      const { data } = resp;
      return data;
    },
    // ======================================================================================
    // ====== IMOVEL ========================================================================
    // ======================================================================================
    
    async ImovelDelete(body) {
      // console.log(body)
      const resp = await API.delete(
        `/imovel/${body.cod_imovel}`
      );
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },
    
    
    async getImovel(p_params) {
      const resp = await API.get(`imovel`, { params: { ...p_params } });
      const { data } = resp;
      if (resp.status == 200) 
        return data.result;
      else
        return data;
    },

    async getTipoImovel(p_params) {
      const resp = await API.get(`imovel/tipo`, { params: { ...p_params } });
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },

    async getSituacoes(p_params) {
      const resp = await API.get(`situacoes`, { params: { ...p_params } });
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },
    
    async getQuadras(p_params) {
      const resp = await API.get(`quadra`, { params: { ...p_params } });
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },

    async getProprietarios(p_params) {
      const resp = await API.get(`proprietarios`, { params: { ...p_params } });
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },

    async postProprietarios(body) {
      const resp = await API.post(`/proprietarios`, JSON.stringify(body));
      const { data } = resp;
      // console.log(body,data)
      return data;
    },
    

    async imovelPut(body) {
      const resp = await API.put(
        `/imovel/${body.cod_imovel}`,
        JSON.stringify(body)
      );
      const { data } = resp;
      return data;
    },

    async ImovelPost(body) {
      const resp = await API.post(`/imovel`, JSON.stringify(body));
      const { data } = resp;
      // console.log(body,data)
      return data;
    },

    async ImovelPostPlanilha(body) {
      const resp = await API.post(`/imovel/importacao_planilha`, JSON.stringify(body));
      const { data } = resp;
      // console.log(body,data)
      return data;
    },

    // ======================================================================================
    // ====== Planos pagamentos ======================================================================
    // ======================================================================================
    async getPlanosPagamento(p_params) {
      const resp = await API.get(`planos_pagamento`, { params: { ...p_params } });
      //console.log("resp",resp);
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },
    
    async PlanosPagamentoPut(body) {
      const resp = await API.put(
        `/planos_pagamento/${body.cod_empreendimento}/${body.cod_planovenda}`,
        JSON.stringify(body)
      );
      const { data } = resp;
      return data;
    },
    async PlanosPagamentoPost(body) {
      const resp = await API.post(`/planos_pagamento`, JSON.stringify(body));
      const { data } = resp;
      // console.log(body,data)
      return data;
    },

    async PlanosPagamentoDelete(body) {
      console.log(body)
      const resp = await API.delete(
        `/planos_pagamento/${body.cod_planovenda}`
      );
      const { data } = resp;
      if (resp.status == 200) return data.result;
      else return data;
    },

    // ======================================================================================
    // ====== MAPA ==========================================================================
    // ======================================================================================

    async MapaPut(body) {
      const resp = await API.put(
        `/mapa/${body.cod_empreendimento}`,
        JSON.stringify(body)
      );
      const { data } = resp;
      // console.log('EmpreendimentoPut33333:::', data)

      return data;
    },

  },

  watch: {
    docDadosBasicos() {
      this.atualiza_abas();
    },
    docFinanceiro() {
      this.atualiza_abas();
    },
    docContabil() {
      this.atualiza_abas();
    },
    docPlanosPagamento() {
      this.atualiza_abas();
    },
    docUnidades() {
      this.atualiza_abas();
    },
  },
});

export default vm_store_Empreendimento;
