import Vue from "vue";
import { API } from "../../services/API"

function initialState() {
  return {
    dados : [],
  }
}

var vm_store_Corretor = new Vue({
  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    async CorretorGet(p_params) {
      const resposta = await API.get("empreendimentocorretores", {
                                                  params: {
                                                      ...p_params
                                                  },
                                                  }
                                      );
        if (resposta) {
          //console.log("corretores", resposta);
          this.dados = [];
          if (resposta.data.result != 'Não possui dados') {
            return resposta.data.result;
        }
      }
    },

    async CorretoresGet() {
      const resposta = await API.get("corretores", {});
      if (resposta) {
        //console.log("corretores", resposta);
        this.dados = [];
        if (resposta.data.result != 'Não possui dados') {
          return resposta.data.result;
        }
      }
    },

  }

})

export default vm_store_Corretor
