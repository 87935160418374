<template>
  <div id="ContatoCadastroMobile">
    <!-- <NavbarLogado /> -->
    <v-toolbar-title class="main-title lighten-2 pa-0 pt-1 pb-5 px-4" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
      <v-container class="container-title pt-0 px-0">
      <v-layout nowrap style="max-height:42px">
        <v-toolbar-title class="title-page text-white mt-2"
          >Cadastro de Contato
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mt-2 d-flex justify-end">
          <v-btn icon dark v-on:click="store_Contato.dialog_cadastro = false">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-layout>
     </v-container>
    </v-toolbar-title>

    <!-- Container Documentos Básicos -->
    <v-container class="container-table mb-3 pt-0 px-0 mt-n6">
      <!-- -- linha dos documentos ------------------------------------------ -->

      <!-- Help -->
      <v-expansion-panels
        class="pa-4 pt-0 px-0 expansion-panel d-none"
        accordion>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="panel-header font-regular-bold"
            disable-icon-rotate
          >
            Documentos Básicos
            <template v-slot:actions>
              <v-icon color="" class="icon-help">
                mdi-help-circle-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-4">
            Conteúdo teste
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Fim Help -->
      
      <v-card class="card d-flex flex-column">
        <v-card-text class="card-content pt-4 px-4 mb-7">
          <!-- Form Cadastro -->
          <v-row class="row d-flex flex-row px-5 justify-center">
        <v-col class="col pl-10">
          <!-- <h4 class="h5 font-regular-bold">Formulário de cadastro</h4> -->

          <v-form class="mt-8 pt-0" ref="form" v-model="valid" lazy-validation>
            <div class="d-flex justify-center">

              <!-- Avatar ----------------------------------------------------------------------->
              <!-- <image-input v-model="store_Contato.contato_edicao.contato_foto">
                <div slot="activator" v-on:click="abre()">
                  <v-avatar
                    size="100px"
                    v-ripple
                    v-if="!store_Contato.contato_edicao.contato_foto"
                    class="grey lighten-2 mb-10"
                  >
                    <span
                      >Clique p/ adicionar avatar
                      <input
                        type="file"
                        id="file"
                        ref="file_new"
                        class="d-none custom-file-input"
                        @change="handleFile($event)"
                      />
                    </span>
                  </v-avatar>
                  <v-avatar size="100px" v-ripple v-else class="mb-3">
                    <img :src="store_Contato.contato_edicao.contato_foto" alt="avatar" />
                  </v-avatar>
                  <input
                    type="file"
                    id="file"
                    ref="file_update"
                    class="d-none custom-file-input"
                    @change="handleFile($event)"
                  />
                </div>
              </image-input> -->
              <!-- <v-slide-x-transition>
                    <div v-if="!store_Contato.contato_edicao.foto">
                      <v-btn class="primary" @click="previewFiles" >Save Avatar</v-btn>
                    </div>
                  </v-slide-x-transition> -->
            </div>

            <v-text-field
              v-model="store_Contato.contato_edicao.contato_nome"
              class="mb-0"
              light
              loader-height="1"
              background-color="#FFF"
              label="Nome"
              placeholder="Nome"
              filled
              required
              dense
              :rules="nameRules"
            ></v-text-field>

            <!-- {{ cpf_cnpj_obrigatorio }}
            {{ email_obrigatorio }}
            {{ celular_obrigatorio }} -->
            <div v-if="cpf_cnpj_obrigatorio === 'SIM'">
              <v-text-field
                v-model="store_Contato.contato_edicao.contato_cpfcnpj"
                v-mask="MASK_CPFCNPJ"
                class="mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="CPF/CNPJ"
                placeholder="CPF/CNPJ"
                filled
                :rules="cpfRules"
                required
                dense
              ></v-text-field>
            </div>
            <div v-else>
              <v-text-field
                v-model="store_Contato.contato_edicao.contato_cpfcnpj"
                v-mask="MASK_CPFCNPJ"
                class="mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="CPF/CNPJ"
                placeholder="CPF/CNPJ"
                filled
                :rules="cpfNaoObrigatorioRules"
                required
                dense
              ></v-text-field>
            </div>
            
            <div v-if="email_obrigatorio === 'SIM'">
              <v-text-field
                v-model="store_Contato.contato_edicao.contato_email"
                class="mb-0"
                light
                loader-height="1"
                hint="Por exemplo: meunome@meuservidor.com.br"
                background-color="#FFF"
                label="Email"
                placeholder="Digite o seu email"
                filled
                :rules="emailRules"
                required
                dense
              ></v-text-field>
            </div>
            <div v-else>
              <v-text-field
                v-model="store_Contato.contato_edicao.contato_email"
                class="mb-0"
                light
                loader-height="1"
                hint="Por exemplo: meunome@meuservidor.com.br"
                background-color="#FFF"
                label="Email"
                placeholder="Digite o seu email"
                filled
                :rules="emailNaoObrigatorioRules"
                required
                dense
              ></v-text-field>
            </div>

            <div v-if="celular_obrigatorio === 'SIM'">
              <v-text-field
                v-model="store_Contato.contato_edicao.contato_celular"
                v-mask="MASK_TELEFONE"
                class="mb-0"
                background-color="#FFF"
                label="Celular"
                placeholder="(00)00000-0000"
                filled
                :rules="foneRules"
                required
                dense
              ></v-text-field>
            </div>
            <div v-else>
              <v-text-field
                v-model="store_Contato.contato_edicao.contato_celular"
                v-mask="MASK_TELEFONE"
                class="mb-0"
                background-color="#FFF"
                label="Celular"
                placeholder="(00)00000-0000"
                filled
                :rules="foneNaoObrigatorioRules"
                required
                dense
              ></v-text-field>
            </div>
          </v-form>
          <!-- Fim Form Cadastro -->
        </v-col>
        <!-- v-mask="`${ maskTELEFONE }`" -->
      </v-row>
      </v-card-text>

      <v-card-actions class="card-action w-100 pa-4 d-flex justify-center">
          <v-btn
            v-on:click="store_Contato.dialog_cadastro=false"
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
          >
            Cancelar
          </v-btn>

        <v-btn
          class="btn white--text"
          color="primary accent-4"
          v-on:click="validate()"
        >
          Salvar
        </v-btn>
      </v-card-actions>

      </v-card>
      <!-- End Accordion -->
    </v-container>

  </div>
</template>

<script>
import Vue from "vue";
import store_Contato from "./store_Contato";
import store_site from "../../store/store_site";
import { API } from "../../services/API";

//import CropImage from "../ComponentesGlobais/CropImage/CropImage.vue";
//import ModalCrop from "../ComponentesGlobais/CropImage/ModalCrop.vue";

// import NavbarLogado from "../NavbarLogado.vue";
import {
  handleFileUpload,
  CpfCnpjIsValid,
  emailIsValid,
  telefoneIsValid,
} from "../../services/funcoes";
import { COR_PRINCIPAL, MASK_CPFCNPJ, MASK_TELEFONE } from "../../services/constantes";

export default {
  name: "ContatoCadastroMobile",

  components: {
    //CropImage,
    //ModalCrop,
    // NavbarLogado,
  },

  data() {
    return {
      store_Contato : store_Contato,
      store_site    : store_site,

      COR_PRINCIPAL   : COR_PRINCIPAL,
      MASK_CPFCNPJ    : MASK_CPFCNPJ,
      MASK_TELEFONE   : MASK_TELEFONE,

      /* Campos */
      acao: "E", // E=Edicao, I=Inclusão
      valid: true,
      API : API,
      cpf_cnpj_obrigatorio: 'SIM',
      email_obrigatorio   : 'SIM',
      celular_obrigatorio : 'SIM',

      /* Formulário Reprovação */
      form: false,
      nameRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      emailRules: [
        (value) => !!value || "O e-mail é obrigatório",
        (value) => (value && emailIsValid(value)) || "O e-mail não é válido",
      ],
      emailNaoObrigatorioRules: [
        // (value) => !!value || "O e-mail é obrigatório",
        (value) => ((!value || value.length <= 0) || (emailIsValid(value))) || "O e-mail não é válido",
      ],
      foneRules: [
        (value) => !!value || "O celular é obrigatório",
        (value) => (value && telefoneIsValid(value)) || "O celular não é válido",
      ],
      foneNaoObrigatorioRules: [
        // (value) => !!value || "O celular é obrigatório",
        (value) => ((!value || value.length <= 0) || telefoneIsValid(value)) || "O celular não é válido",
      ],
      cpfRules: [
        (value) => !!value || "O CPF é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
      ],
      cpfNaoObrigatorioRules: [
        (value) => ((!value || value.length <= 0) || (value && CpfCnpjIsValid(value))) || "O CPF não é válido",      
      ],
    };
  },

  mounted(){
    this.buscaEmpreendimento()
  },
  methods: {
    async buscaEmpreendimento() {
      console.log("🚀 ~ buscaEmpreendimento ~ buscaEmpreendimento:")
      const {data} = await API.get(`empreendimento`, { params: { cod_empreendimento : this.$route.params.cod_empreendimento, fields : "json_config" } });
      
      console.log("🚀 ~ buscaEmpreendimento ~ data:", data)
      if(data.result != 'Não possui dados'){
        let dados = JSON.parse(data.result.json_config)
        if(!!dados.contato){
          this.cpf_cnpj_obrigatorio = dados.contato.cpf_cnpj
          this.email_obrigatorio = dados.contato.email
          this.celular_obrigatorio = dados.contato.celular
        }
        console.log("🚀 ~ buscaEmpreendimento ~ data:",  this.cpf_cnpj_obrigatorio, this.email_obrigatorio, this.celular_obrigatorio)

      }
    },
    abre() {
      if (!this.store_Contato.contato_edicao.contato_foto) {
        this.$refs.file_new.click();
      } else {
        this.$refs.file_update.click();
      }
    },

    fecha_alert() {
      this.alert = false;
    },

    /* Método que recebe arquivo de imagem */
    handleFile({ target }) {
      let reader = new FileReader();
      if (target.files && target.files.length > 0) {
        reader.readAsDataURL(target.files[0]);
        reader.onload = async (file) => {
          this.store_Contato.contato_edicao.contato_foto = file.target.result;
        };
      }
    },

    async validate() {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {

        // Editando ----------------
        var ls_form;
        if (this.store_Contato.contato_edicao.cod_contato) {
          //console.log("EDICAO", this.store_Contato.contato_edicao);
          ls_form = await this.store_Contato.ContatoPut(this.store_Contato.contato_edicao);
          // Incluindo ---------------
        } else {
          //console.log("INCLUSAO", this.store_Contato.contato_edicao);
          ls_form = await this.store_Contato.ContatoPost(this.store_Contato.contato_edicao);
        }

        if (ls_form.result.message == "success") {
          this.store_Contato.dialog_cadastro = false;
          // Inclusão, então reabrir consulta
          if (!this.store_Contato.contato_edicao.cod_contato) {
            var lo_params = {};
            await this.store_Contato.ContatoGet(lo_params);
          }
          if (this.store_Contato.dados) {
            var li_Index = await this.store_Contato.dados.findIndex(o => (o.cod_contato == this.store_Contato.contato_edicao.cod_contato));
            Vue.set(this.store_Contato.dados, li_Index, this.store_Contato.contato_edicao);
          }
          //this.store_Contato.ContatoGet({});
        } else {
          this.store_site.alert_cor       = "#FF0000",
          this.store_site.alert_timeout   = 10000,
          this.store_site.alert_msg       = ls_form.errors.trim();
          this.store_site.alert           = true;
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
  .main-title {
    background: #0548a3;
  }

  .text-white {
    font-family: "Montserrat", sans-serif !important;
    font-size: 20px;
    color: white;
  }

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .container-table {
    border-radius: 8px !important;
  }

  @media (max-width: 375px) {
    .container-table {
      max-width: 100% !important;
      border-radius: 16px !important;
    }
  }

  .row {
    background: #E8E8E8!important;
    padding: 0px !important;
  }
  
  .col {
    padding-top: 22px !important;
    padding-left: 15px !important;
  }

  .card, .card-action {
    border-radius: 8px;
    background: #E8E8E8 !important;
  }

  @media (max-width: 599px) {
    .row {
      padding: 0px !important;
    }

    .col {
      padding-top: 22px !important;
      padding-left: 15px !important;
    }
  }

  /* ======================== AVATAR UPLOAD ===================== */
  .mx-auto {
    margin: auto;
  }

  .input-file {
    width: 110px !important;
    height: 35px !important;
    border-radius: 50%;
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    outline: none !important;
    z-index: 2;
  }

  .avatar-container__file {
    width: 100%;
    height: 100%;
    margin: 0px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    outline: none;
  }

  .h5 {
    font-family: "Montserrat", sans-serif;
    color: rgba(0, 0, 0, 0.87);
  }

  .h5::after {
    display: block;
    width: 50px;
    height: 3px;
    margin-top: 0.2rem;
    content: "";
    background: #274abb;
  }

  @media (max-width: 599px) {
    .card-content {
      margin-bottom: 4px !important;
    }

    .card-action {
      padding-top: 0px !important;
      justify-content: space-around !important;
    }
  }

  @media (max-width: 375px) {
    .container-table {
      padding: 0px !important;
    }
  }

  .btn {
    width: 120px;
  }

</style>