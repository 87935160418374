import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
//import OSM from 'ol/source/OSM'
import BingMaps from "ol/source/BingMaps.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { defaults as DefaultControls, FullScreen } from "ol/control";
import { DragRotateAndZoom, defaults as defaultInteractions } from "ol/interaction";
import { Draw, Modify, Snap } from 'ol/interaction.js';
import { Point } from 'ol/geom';
import { Fill, Style, Stroke, Icon } from "ol/style.js";
import { toLonLat, fromLonLat, transformExtent } from "ol/proj.js";
import Select from "ol/interaction/Select.js";
import { click, pointerMove, altKeyOnly } from 'ol/events/condition.js';
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import {
    Projection,
    addCoordinateTransforms,
    addProjection,
    get as getProjection,
    getTransform
  } from "ol/proj";
import { transform } from "ol/proj";
import { rotate } from "ol/coordinate";
import { getCenter } from "ol/extent";
import Translate from 'ol/interaction';
import GeoJSON from "ol/format/GeoJSON";
import OlImageLayer from "ol/layer/Image";
import ImageStatic from "ol/source/ImageStatic";

proj4.defs(
    "EPSG:27700",
    "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 " +
        "+x_0=400000 +y_0=-100000 +ellps=airy " +
        "+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 " +
        "+units=m +no_defs"
    );
register(proj4);
    
const rotateProjection = (projection, angle, extent) => {
    function rotateCoordinate(coordinate, angle, anchor) {
        var coord = rotate(
        [coordinate[0] - anchor[0], coordinate[1] - anchor[1]],
        angle
        );
        return [coord[0] + anchor[0], coord[1] + anchor[1]];
    }

    function rotateTransform(coordinate) {
        return rotateCoordinate(coordinate, angle, getCenter(extent));
    }

    function normalTransform(coordinate) {
        return rotateCoordinate(coordinate, -angle, getCenter(extent));
    }

    var normalProjection = getProjection(projection);

    var rotatedProjection = new Projection({
        code:
        normalProjection.getCode() +
        ":" +
        angle.toString() +
        ":" +
        extent.toString(),
        units: normalProjection.getUnits(),
        extent: extent
    });
    addProjection(rotatedProjection);

    addCoordinateTransforms(
        "EPSG:4326",
        rotatedProjection,
        function(coordinate) {
            return rotateTransform(transform(coordinate, "EPSG:4326", projection));
        },
        function(coordinate) {
            return transform(normalTransform(coordinate), projection, "EPSG:4326");
        }
    );

    addCoordinateTransforms(
        "EPSG:3857",
        rotatedProjection,
        function(coordinate) {
            return rotateTransform(transform(coordinate, "EPSG:3857", projection));
        },
        function(coordinate) {
            return transform(normalTransform(coordinate), projection, "EPSG:3857");
        }
    );

    // also set up transforms with any projections defined using proj4
    if (typeof proj4 !== "undefined") {
        var projCodes = Object.keys(proj4.defs);
        projCodes.forEach(function(code) {
        var proj4Projection = getProjection(code);
        if (!getTransform(proj4Projection, rotatedProjection)) {
            addCoordinateTransforms(
            proj4Projection,
            rotatedProjection,
            function(coordinate) {
                return rotateTransform(
                transform(coordinate, proj4Projection, projection)
                );
            },
            function(coordinate) {
                return transform(
                normalTransform(coordinate),
                projection,
                proj4Projection
                );
            }
            );
        }
        });
    }

    //console.log(rotatedProjection)
    return rotatedProjection;

}

export {
    rotateProjection	
}
